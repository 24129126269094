@use "../../../../styles/mixin";
@use "../../../../styles/variables";

.modal {
  width: 288px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include mixin.devices(tablet) {
    width: 467px;
  }

  & h2 {
    margin-top: 0;
    margin-bottom: 8px;

    color: variables.$main-text-color;
    font-family: variables.$fontMontserrat;
    font-size: 20px;
    font-weight: 800;
    line-height: 1.2;
    letter-spacing: 0;
    text-transform: uppercase;

    @include mixin.devices(tablet) {
      font-size: 28px;
    }
  }

  & p {
    margin-top: 0;
    margin-bottom: 24px;

    color: variables.$gray-color;
    font-family: variables.$fontMontserrat;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0;
    text-align: center;
    text-wrap: pretty;

    @include mixin.devices(tablet) {
      font-size: 16px;
    }
  }

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    color: variables.$main-text-color;
    font-family: variables.$proximaNova;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0;
    text-transform: capitalize;

    @include mixin.devices(tablet) {
      font-size: 20px;
    }

    & svg {
      width: 16px;
      height: 16px;

      @include mixin.devices(tablet) {
        width: 20px;
        height: 20px;
      }
    }
  }

  & label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    gap: 4px;

    color: variables.$black;
    font-family: variables.$proximaNova;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.375;
    letter-spacing: 0em;

    & span {
      margin-left: 12px;
    }

    @include mixin.devices(tablet) {
      font-size: 16px;
      font-weight: 600;
    }

    & > input {
      padding: 10px 12px;

      border-radius: 4px;
      border: 1px solid #d4ebfd;
      outline: none;

      color: variables.$black;
      font-family: variables.$fontMontserrat;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0;
    }
  }
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 24px;

  @include mixin.devices(tablet) {
    flex-direction: row;
    gap: 24px;
  }

  & button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 256px;
    padding: 12px 0;

    font-family: variables.$proximaNova;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.56;
    letter-spacing: 0;
    text-transform: uppercase;

    border: none;
    border-radius: 12px;
    box-shadow: 0px 11px 22px 0px #2c83c842;

    cursor: pointer;

    @include mixin.devices(tablet) {
      width: 197.5px;
      padding: 14px 0;
      font-size: 18px;
    }
  }
}

.cancelButton {
  background: variables.$button-gradient-color;
  color: variables.$white;

  &:hover,
  &:active,
  &:focus {
    background: variables.$button-active-gradient-color;
  }
}

.deleteButton {
  background: variables.$white;
  color: #ff656d;
  border: 1px solid #ff656d !important;
  box-shadow: 0px 11px 22px 0px #c8372c42 !important;

  &:disabled {
    color: #c3c3c3;
    border: 1px solid #c3c3c3 !important;
    box-shadow: 0px 11px 22px 0px #2c83c842 !important;
  }
}
