@use "../../../../styles/variables";
@use "../../../../styles/mixin";

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0 16px;
  background-color: #00000066;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.modal {
  position: relative;

  padding: 32px 16px;

  border-radius: 15px;
  box-shadow: 0px 16px 40px 0px #3c85bf2e;
  background: linear-gradient(180deg, #ebf6ff 0%, #cfe6f9 100%);
  background-size: contain;
}

.closeModalButton {
  position: absolute;
  top: -12px;
  right: -12px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;

  width: 40px;
  height: 40px;

  border: none;
  border-radius: 50%;

  background-color: #fff;
  cursor: pointer;
  @include mixin.devices(phone-tablet) {
    top: -18px;
    right: -18px;
  }
}

.closeModalIcon {
  display: block;
  width: 12px;
  height: 12px;
}
