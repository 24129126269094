@use "../../../styles/mixin";
@use "../../../styles/variables";

.footer {
  background-color: variables.$gray-color;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 28px 0;
  gap: 28px;

  @include mixin.devices(tablet) {
    flex-direction: column;
    justify-content: center;

    gap: 48px;
  }

  @include mixin.devices(desktop) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.logoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include mixin.devices(desktop) {
    align-items: start;
  }
}

.linksWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  @include mixin.devices(tablet) {
    flex-direction: unset;
    flex-wrap: wrap;
    align-items: baseline;
    width: 688px;
  }

  @include mixin.devices(desktop) {
    flex-wrap: nowrap;
    width: auto;
    gap: 42px;
  }

  @include mixin.devices(desktop-plus) {
    gap: 56px;
  }
}

.copyrightWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  width: 100%;
  padding: 16px 0;
  text-align: center;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.5px;
    opacity: 0.3;

    background-color: variables.$white;
  }

  @include mixin.devices(desktop) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 0;
  }
}

.copyrightText {
  font-size: 12px;
  color: variables.$white;

  @include mixin.devices(phone-tablet) {
    font-size: 14px;
  }

  @include mixin.devices(tablet) {
    font-size: 16px;
  }

  @include mixin.devices(desktop-plus) {
    font-size: 18px;
  }
}

.omiPlayLink {
  color: variables.$white;
  font-weight: 500;
  font-size: 12px;
  text-decoration: none;
  text-align: center;

  cursor: pointer;

  @include mixin.devices(phone-tablet) {
    font-size: 14px;
  }

  @include mixin.devices(tablet) {
    font-size: 16px;
  }

  @include mixin.devices(desktop-plus) {
    font-size: 18px;
  }
}

.listWrapper {
  opacity: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: opacity 200ms ease-in;

  @include mixin.devices(tablet) {
    align-items: flex-start;
  }
}

.listLink {
  display: block;
  padding: 8px 0;
  color: variables.$white;
  font-weight: 600;
  font-size: 12px;

  @include mixin.devices(phone-tablet) {
    font-size: 14px;
  }

  @include mixin.devices(desktop-plus) {
    font-size: 16px;
  }
}

.listItemWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  height: 20px;
  overflow: hidden;

  @include mixin.devices(tablet) {
    align-items: center;
    width: 328px;
  }

  @include mixin.devices(desktop) {
    width: auto;
    align-items: flex-start;
  }
}

.listItemWrapperActive {
  height: auto;
  overflow: unset;
  & ul {
    opacity: 1;
    cursor: auto;
  }
}

.listItemTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  margin: 0;
  font-family: variables.$fontMontserrat;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  color: variables.$white;

  @include mixin.devices(phone-tablet) {
    font-size: 18px;
  }

  @include mixin.devices(desktop-plus) {
    font-size: 20px;
  }
}
