@use "../../../../styles/mixin";
@use "../../../../styles/variables";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: variables.$fontMontserrat;
  line-height: 17px;
  letter-spacing: 0px;

  width: 100%;

  height: 36px;

  @include mixin.devices(phone-tablet) {
    height: 40px;
  }

  @include mixin.devices(tablet) {
    height: 44px;
  }

  @include mixin.devices(desktop) {
    height: 46px;
  }

  @include mixin.devices(desktop-plus) {
    height: 48px;
  }
}

.burgerBtn {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 0;

  cursor: pointer;

  @include mixin.devices(tablet) {
    display: none;
  }
}

.link {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 22px;
  border: none;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
}

.linkWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  color: variables.$white;
  font-weight: 600;
  font-size: 12px;
  font-family: "Montserrat", sans-serif;

  & > :not(:last-child)::after {
    content: " ";
    display: block;
    width: 2px;
    height: 100%;
    background-color: variables.$header-separator;
    margin: 0 6px 0 2px;
  }

  @include mixin.devices(phone-tablet) {
    font-size: 14px;
  }

  @include mixin.devices(tablet) {
    margin-right: auto;
    font-size: 16px;
  }
}

.btn {
  display: flex;
  align-items: center;
  gap: 4px;
  border: none;
  background-color: transparent;
  padding: 0;
  padding-block: 0;
  padding-inline: 0;

  color: variables.$white;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: 1.21;
  height: 22px;

  letter-spacing: 0;
  cursor: pointer;
}

.rocketIcon {
  display: block;

  @include mixin.devices(tablet) {
    display: none;
  }
}

.dailyChallengesText {
  display: none;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.1;
  letter-spacing: 0px;
  text-align: left;

  @include mixin.devices(tablet) {
    display: block;
  }
}
