@use "../../../../styles/variables";
@use "../../../../styles/mixin";

body.modal-open {
  overflow: hidden;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  padding: 0 16px;

  background-color: #00000066;

  z-index: 999;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  padding: 24px;

  border-radius: 15px;
  box-shadow: 0px 16px 40px 0px #3c85bf2e;
  background: linear-gradient(180deg, #ebf6ff 0%, #cfe6f9 100%);
  background-size: contain;
}

.closeModalButton {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border: none;
  border-radius: 50%;

  background-color: #fff;
  cursor: pointer;

  @include mixin.devices(desktop) {
    top: 24px;
    right: 24px;
  }
}

.closeModalIcon {
  display: block;
  width: 12px;
  height: 12px;
}
