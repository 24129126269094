@use "../../../styles/mixin";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  margin: 0 auto;
  width: 100%;

  @include mixin.devices(tablet) {
    padding: 0 40px;
  }

  @include mixin.devices(desktop) {
    padding: 0 80px;
    max-width: 1280px;
  }

  @include mixin.devices(desktop-plus) {
    padding: 0 140px;
    max-width: 1640px;
  }
}
