@use "../../../styles/mixin";
@use "../../../styles/variables";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: variables.$white;
}

.tabsWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-top: 4px;
  background: variables.$white;
}

.tabIcon {
  display: block;
  width: 24px;
  height: 24px;
}

.logo {
  width: 88px;

  @include mixin.devices(tablet) {
    width: 138px;
  }
}

.sudokuProTab {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px 7px 3px;
  border-radius: 8px 8px 0px 0px;
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: #b8dfff;
  background: variables.$main-text-color;
  cursor: pointer;

  @include mixin.devices(tablet) {
    padding: 6px 12px 2px;
  }
}

.sudokuProTabText {
  display: none;
  margin: 0;
  font-family: variables.$fontMontserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.27;
  letter-spacing: 0;
  text-align: left;
  color: variables.$white;

  @include mixin.devices(desktop) {
    display: block;
  }
}

.mahjongTabText {
  display: none;
  margin: 0;
  font-family: variables.$fontMontserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.27;
  letter-spacing: 0;
  text-align: left;
  color: #009e84;

  @include mixin.devices(desktop) {
    display: block;
  }
}

.omiPlayTabText {
  display: none;
  margin: 0;
  font-family: variables.$fontMontserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.27;
  letter-spacing: 0;
  text-align: left;
  color: #5e7ef0;

  @include mixin.devices(desktop) {
    display: block;
  }
}

.otherSiteTab {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px 7px 3px;

  border-radius: 8px 8px 0px 0px;
  border-width: 1px 1px 0px 1px;
  border-style: solid;
  border-color: #b8dfff;

  cursor: pointer;

  @include mixin.devices(tablet) {
    padding: 6px 12px 2px;
  }
}
