@use "../../../styles/mixin";
@use "../../../styles/variables";

.link {
  color: variables.$link-color;
  font-weight: 500;
  font-size: 16px;
  text-decoration: none;
  text-align: center;

  cursor: pointer;
}

.displayBlock {
  display: block;
}

.socialsMobileMenu {
  display: flex;
  justify-content: center;
  gap: 16px;
  fill: variables.$main-text-color;
  stroke: variables.$main-text-color;
}

.socialsFooter {
  display: flex;
  justify-content: center;
  gap: 32px;
  fill: #ebf6ff;
  stroke: #ebf6ff;

  @include mixin.devices(tablet) {
    align-items: center;
  }
}

.logoSvgHeader {
  width: 95px;
  fill: variables.$main-text-color;

  @include mixin.devices(phone-tablet) {
    width: 150px;
  }
  @include mixin.devices(desktop) {
    width: 190px;
  }
}

.logoSvgFooter {
  width: 150px;
  fill: variables.$white;

  @include mixin.devices(desktop-plus) {
    width: 190px;
  }
}

.loginBtnMobile {
  border: none;
  padding: 0;
  width: 40px;
  height: 40px;

  @include mixin.devices(tablet) {
    display: none;
  }
}

.buyMeACoffeeButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.buyMeACoffeeFooterButton {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  gap: 8px;
}

.buyMeACoffeeIcon {
  width: 30px;
  height: 30px;
}

.buyMeACoffeeText {
  color: variables.$white;
  font-size: 16px;
  font-weight: 500;
}

.buyMeACoffeeHeaderText {
  color: variables.$main-text-color;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;

  @include mixin.devices(desktop) {
    font-size: 16px;
  }
}
