@use "../../../../styles/variables";
@use "../../../../styles/mixin";

.title {
  margin: 0 auto 16px;
  max-width: 350px;
  color: variables.$main-text-color;
  text-align: center;
  text-transform: uppercase;
  font-size: 26px;
  font-weight: 800;
  line-height: 1.23;
  letter-spacing: 0;
  @include mixin.devices(phone-tablet) {
    max-width: 550px;
    font-size: 40px;
  }
}

.description {
  color: variables.$gray-color;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.28;
  letter-spacing: 0;
  text-align: left;

  @include mixin.devices(phone-tablet) {
    font-size: 17px;
  }
}

.value {
  color: variables.$text-gray-color;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.28;
  letter-spacing: 0;
  text-align: left;
  margin-left: 8px;

  @include mixin.devices(phone-tablet) {
    font-size: 17px;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 24px auto 48px;
  gap: 12px;
}

.textLineWrapper {
  display: flex;
  flex-direction: row;
}

.buttonWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  margin: 0 auto;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 232px;
  padding: 15px 15px 14px;

  border: none;
  border-radius: 12px;

  background: variables.$button-gradient-color;
  box-shadow: 0px 11px 22px 0px #2c83c842;

  color: variables.$secondary-color;
  font-family: variables.$proximaNova;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.23;
  letter-spacing: 0;

  cursor: pointer;
}
