@use "../../../styles/variables";
@use "../../../styles/mixin";

.authBox {
  margin: 16px auto 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 288px;
  max-width: 630px;

  gap: 16px;
  padding: 32px 16px;
  border-radius: 16px;

  box-shadow: 0px 16px 40px 0px #3c85bf2e;
  @include mixin.devices(phone-tablet) {
    margin: 80px auto 90px;
    padding: 42px 60px;
    border-radius: 15px;
    gap: 0;
  }
  @include mixin.devices(tablet) {
    width: 630px;
    margin: 80px auto 90px;
    padding: 42px 110px;
    border-radius: 15px;
    gap: 0;
  }
}

.title {
  margin: 0;
  padding: 0;
  color: variables.$main-text-color;
  font-size: 22px;
  font-weight: 700;
  @include mixin.devices(phone-tablet) {
    font-size: 32px;
    margin-bottom: 24px;
  }
  @include mixin.devices(tablet) {
    font-size: 42px;
    margin-bottom: 32px;
  }
}

.button {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 256px;
  border-radius: 12px;
  font-family: variables.$fontMontserrat;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.6;
  @include mixin.devices(tablet) {
    min-width: 410px;
    font-size: 18px;
  }
}

.authButton {
  padding: 11px 0 11px 16px;
  border: 1px solid variables.$main-color;
  background-color: transparent;
  text-align-last: left;
  cursor: pointer;
  @include mixin.devices(phone-tablet) {
    margin-bottom: 12px;
    &:nth-child(3) {
      margin-bottom: 24px;
    }
  }
  @include mixin.devices(tablet) {
    margin-bottom: 14px;
    &:nth-child(3) {
      margin-bottom: 32px;
    }
  }
}

.authButtonIcon {
  display: block;
  width: 28px;
  height: 28px;
  margin-right: 16px;
  @include mixin.devices(tablet) {
    width: 32px;
    height: 32px;
    margin-right: 20px;
  }
}

.goBackButton {
  display: block;
  padding: 15px 0 14px 0;
  border: none;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-family: variables.$proximaNova;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.21;
  background: variables.$button-gradient-color;
  cursor: pointer;
}

.logInButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px;
  border: none;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;

  min-width: 40px;
  height: 40px;

  @include mixin.devices(tablet) {
    min-width: 104px;

    white-space: nowrap;
  }
}

.loginText {
  display: none;
  color: variables.$white;
  font-family: variables.$proximaNova;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.21;
  letter-spacing: 0px;

  text-transform: uppercase;
  @include mixin.devices(tablet) {
    display: block;
  }
}

.logOutButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px;

  border: 2px solid variables.$main-text-color;
  border-radius: 12px;
  background: variables.$white;
  cursor: pointer;
  width: 100%;
}

.logoutText {
  color: variables.$main-text-color;
  font-size: 17px;
  font-weight: 600;
  font-family: variables.$fontMontserrat;
  text-transform: capitalize;
}

.logOutDesktopBtn {
  display: block;
  border-radius: 50%;
  border: 0px solid;
  padding: 0;
  width: 30px;
  height: 30px;
  background-color: variables.$main-color;
  font-weight: 700;
  text-transform: uppercase;
  font-family: variables.$proximaNova;
  color: white;
  cursor: pointer;

  @include mixin.devices(phone-tablet) {
    width: 34px;
    height: 34px;
  }

  @include mixin.devices(tablet) {
    width: 38px;
    height: 38px;
  }

  @include mixin.devices(desktop) {
    width: 40px;
    height: 40px;
  }

  @include mixin.devices(desktop-plus) {
    width: 42px;
    height: 42px;
  }
}
