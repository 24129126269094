@use "../../../styles/variables";
@use "../../../styles/mixin";

.header {
  position: relative;
  z-index: 10;
  background: variables.$main-text-color;
}

.innerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menuAndLogoWrapper {
  display: flex;
  align-items: center;
}

.menuWrapper {
  display: flex;
  align-items: center;

  gap: 4px;

  @include mixin.devices(phone-tablet) {
    gap: 16px;
  }
}

.menuItemRewardsWrapper {
  color: variables.$main-text-color;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;

  & > :not(:last-child)::after {
    content: " ";
    display: block;
    width: 2px;
    height: 100%;
    background-color: variables.$game-grid-rows2;
    margin: 0 4px;
    @include mixin.devices(phone-tablet) {
      margin: 0 8px;
    }
  }
}

.menuItem {
  color: variables.$main-text-color;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  min-width: 20px;
}

.menuItemStyledLink {
  color: inherit;
  cursor: pointer;
  height: 24px;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
