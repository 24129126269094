@use "../../../../styles/variables";
@use "../../../../styles/mixin";

body.modal-open {
  overflow: hidden;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  padding: 0 16px;
  background-color: #00000066;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal {
  position: relative;

  padding: 0 16px 32px;

  border-radius: 15px;
  box-shadow: 0px 16px 40px 0px #3c85bf2e;
  background: url("/images/confettiMobile.svg") no-repeat top center, linear-gradient(180deg, #ebf6ff 0%, #cfe6f9 100%);
  background-size: contain;
  @include mixin.devices(phone-tablet) {
    background: url("/images/confetti.svg") no-repeat top center, linear-gradient(180deg, #ebf6ff 0%, #cfe6f9 100%);
    background-size: contain;
  }
}

.closeAwardButton {
  position: absolute;
  top: 4px;
  right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  border: none;
  border-radius: 50%;

  background-color: #fff;
  cursor: pointer;
  @include mixin.devices(tablet) {
    top: -24px;
    right: -24px;
  }
}

.closeModalIcon {
  display: block;
  width: 12px;
  height: 12px;
}

.modalTitle {
  margin: 86px auto 16px;
  max-width: 350px;
  color: variables.$main-text-color;
  text-align: center;
  text-transform: uppercase;
  font-size: 26px;
  font-weight: 800;
  line-height: 1.23;
  letter-spacing: 0;
  @include mixin.devices(phone-tablet) {
    max-width: 550px;
    font-size: 40px;
  }
}

.modalText {
  margin: 0 auto 11px;
  max-width: 344px;
  color: variables.$gray-color;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.28;
  letter-spacing: 0;
  text-align: center;
  @include mixin.devices(phone-tablet) {
    max-width: 415px;
    font-size: 17px;
  }
}

.modalCupImgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 21px;
  padding-bottom: 10px;
  background: url("/images/congratulationsLight.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  @include mixin.devices(tablet) {
    padding-top: 30px;
    padding-bottom: 14px;
  }
}

.modalCupImg {
  display: block;
  width: 100px;
  @include mixin.devices(tablet) {
    width: 130px;
  }
}

.modalOkButton {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 256px;
  margin: 19px auto 0;
  padding: 15px 15px 14px;

  border: none;
  border-radius: 12px;

  background: variables.$button-gradient-color;
  box-shadow: 0px 11px 22px 0px #2c83c842;

  color: variables.$secondary-color;
  font-family: variables.$proximaNova;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.23;
  letter-spacing: 0;

  cursor: pointer;
}
