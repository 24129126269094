@use "../../../../styles/variables";
@use "../../../../styles/mixin";

body.modal-open {
  overflow: hidden;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  padding: 0 16px;

  background-color: #00000066;

  z-index: 999;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 24px 16px;

  border-radius: 15px;
  box-shadow: 0px 16px 40px 0px #3c85bf2e;
  background: linear-gradient(180deg, #ebf6ff 0%, #cfe6f9 100%);
  background-size: contain;

  @include mixin.devices(tablet) {
    transform: translate(-50%, -50%);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.modalTitle {
  margin: 0 0 12px 0;
  min-width: 212px;

  color: variables.$main-text-color;
  font-size: 22px;
  line-height: 1.22;
  font-weight: 700;
  letter-spacing: 0;
  text-align: left;

  @include mixin.devices(tablet) {
    font-size: 32px;
  }
}

.modalDescrption {
  margin: 0 auto 24px auto;

  color: variables.$text-gray-color;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.57;
  letter-spacing: 0;

  @include mixin.devices(tablet) {
    font-size: 18px;
  }
}

.btnWrapper {
  display: flex;
  justify-content: center;

  width: 100%;
}

.cancelBtn {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 15px 0 14px 0;
  margin-left: 16px;

  width: 120px;

  border: none;
  border-radius: 12px;

  color: #fff;
  text-align: center;
  font-family: variables.$proximaNova;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.21;
  text-transform: uppercase;

  background: variables.$button-gradient-color;
  cursor: pointer;

  @include mixin.devices(phone-table) {
    width: 150px;
  }
}

.logOutBtn {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  margin-left: auto;
  padding: 15px 0 14px 0;

  width: 120px;

  border: none;
  border-radius: 12px;

  color: #fff;
  text-align: center;
  font-family: variables.$proximaNova;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.21;
  text-transform: uppercase;

  background: variables.$button-gradient-color;
  cursor: pointer;

  @include mixin.devices(phone-table) {
    width: 150px;
  }
}

.cancelBtnOK {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  margin: 0 auto;
  padding: 15px 0 14px 0;
  width: 120px;

  border: none;
  border-radius: 12px;

  color: #fff;
  text-align: center;
  font-family: variables.$proximaNova;
  font-size: 17px;
  font-weight: 700;
  line-height: 1.21;
  text-transform: uppercase;

  background: variables.$button-gradient-color;
  cursor: pointer;

  @include mixin.devices(phone-table) {
    width: 150px;
  }
}
