$main-color: #0473cc;
$text-gray-color: #1e1e1e;
$gray-color: #333333;
$secondary-color: #ebf6ff;
$main-text-color: #1591f4;
$game-grid-rows: #ebf6ff;
$game-grid-rows2: #b5defe;
$link-color: #2196f3;
$gray-text-color: #616161;
$white: white;
$black: #000000;
$text-italic-color: #7a7a7a;
$gray-secondary-text-color: #83869e;
$header-separator: #5ab0f2;
$grey: #5c5c5c;
$scrollbar: rgba(51, 51, 51, 0.4);
$date-cell-selected-color: #289af4;
$disabled-date-color: #8d8d8d;
$white: #ffffff;

// Gradients

$button-gradient-color: linear-gradient(90deg, rgba(105, 189, 255, 1) 0%, rgba(33, 150, 243, 1) 100%);
$button-active-gradient-color: linear-gradient(90deg, rgba(97, 185, 255, 1) 0%, rgba(25, 146, 242, 1) 100%);
$main-gradient: linear-gradient(93.62deg, #b9dcf7 3.14%, #2196f3 100%);

// Fonts

$fontMontserrat: "Montserrat", sans-serif;
$proximaNova: "Proxima Nova", sans-serif;
$roboto: "Roboto", sans-serif;

// Borders

$borderSecondary: #6d6b6b;

// Box-shadow

$box-shadow-main: 0px 11px 22px 0px rgba(44, 131, 200, 0.25);
