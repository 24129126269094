@mixin devices($breakpoint) {
  @if $breakpoint == desktop-xl {
    @media only screen and (min-width: 1920px) {
      @content;
    }
  }

  @if $breakpoint == desktop-plus {
    @media only screen and (min-width: 1640px) {
      @content;
    }
  }

  // TODO needs to remove this breakpoint, it is not on the figma.
  @if $breakpoint == desktop-calendar {
    @media only screen and (min-width: 1585px) {
      @content;
    }
  }

  @if $breakpoint == desktop {
    @media only screen and (min-width: 1280px) {
      @content;
    }
  }

  @if $breakpoint == tablet {
    @media only screen and (min-width: 768px) {
      @content;
    }
  }

  @if $breakpoint == phone-tablet {
    @media only screen and (min-width: 480px) {
      @content;
    }
  }
}
