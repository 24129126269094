@use "../../../styles/variables";
@use "../../../styles/mixin";

.selectorWrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  display: flex;

  @include mixin.devices(tablet) {
    position: relative;
    z-index: 10;
    padding: 10px;
  }
}

.toggleModalBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border: none;
  padding: 0;

  background-color: transparent;

  text-transform: capitalize;
  color: white;
  font-family: variables.$fontMontserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.31;
  letter-spacing: 0;

  cursor: pointer;

  @include mixin.devices(phone-tablet) {
    font-size: 18px;
  }

  @include mixin.devices(desktop-plus) {
    font-size: 20px;
  }
}

.langListWrapper {
  position: absolute;
  top: -480px;

  margin: 0;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  background-color: variables.$grey;
  border-radius: 4px;
  border: 1px solid variables.$borderSecondary;
  backdrop-filter: blur(2px);

  opacity: 0;
  transition: opacity 300ms ease-in-out;

  width: 180px;
  height: 480px;
  overflow-y: scroll;
  pointer-events: none;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: variables.$borderSecondary;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: variables.$scrollbar;
    border-radius: 4px;
  }

  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

.langItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 0;
  gap: 8px;

  text-transform: capitalize;
  color: #bcbcbc;
  font-family: Montserrat;
  font-size: 17px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  cursor: pointer;
}
.active {
  color: white;
}

.chevronIcon {
  margin-left: 6px;
}
