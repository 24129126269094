@use "../../../../styles/variables";
@use "../../../../styles/mixin";

.wrapper {
  position: fixed;
  top: -100%;
  height: 100%;
  width: 100%;
  padding: 36px 38px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;

  background: variables.$white url("/images/rectangle.png") no-repeat center/contain;

  opacity: 0;
  transition: top 0.5s, opacity 0.3s;
  z-index: 100;
  overflow-y: auto;
}

.logo {
  fill: variables.$main-text-color;
}

.mobileMenuActive {
  top: 72px;
  opacity: 1;

  @include mixin.devices(phone-tablet) {
    top: 76px;
  }
}

.menuItem {
  padding: 5px;
  color: variables.$main-text-color;

  font-weight: 600;
  font-size: 17px;

  cursor: pointer;
}

.menuItemStyledLink {
  display: block;
  padding: 12px 0;
  color: variables.$main-text-color;

  font-weight: 600;
  font-size: 17px;

  cursor: pointer;
}

.menuItemRewardsWrapper {
  position: relative;

  color: variables.$main-text-color;
  font-weight: 600;

  & a:first-child {
    padding: 10px 0;
  }

  & a:last-child {
    margin-left: 16px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 41px;
    width: 2px;
    height: 100%;
    background-color: variables.$game-grid-rows2;
  }
}

.playSudokuLink {
  width: 100%;
  background: variables.$button-gradient-color;
  padding: 15px 29px 14px;
  border-radius: 12px;
  text-align: center;
  font-family: variables.$proximaNova;
  color: variables.$white;
  font-weight: 700;
  font-size: 17px;
  line-height: 1.21;
  text-decoration: none;
  text-transform: uppercase;
}
